<template>
  <v-app>
    <cNavbar v-show="isImportant" />
    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import cNavbar from "./components/c_Navbar.vue";

export default {
  components: {
    cNavbar,
  },
  data() {
    return {
      isDefault: true,
    };
  },
  computed: {
    // currentRouteName() {

    //   // const router_name = this.$route.name;
    //   // if (router_name == "/login") {
    //   //   this.isDefault = false;
    //   //   return false;
    //   // } else {
    //   //   this.isDefault = true;
    //   //   return true;
    //   // }
    // },

    isImportant() {
      console.log(this.$route.name);
      if (this.$route.name == "login" || this.$route.name == "dashboard") {
        return false;
      } else {
        return true;
      }
    },
  },
  methods: {
    checkShow() {
      this.$emit("toggleDrawer");
      const router_name = this.$route.name;
      console.log(router_name);
      if (router_name == "/login") {
        this.isDefault = false;
        return false;
      } else {
        this.isDefault = true;
        return true;
      }
    },
  },
};
</script>

<style lang="scss"></style>
