<template>
  <v-app>
    <v-main>
      <c_Banner></c_Banner>
      <!-- <c-header></c-header> -->
      <c_Proizvodstvo/>
      <c_Products />
      <c_Contact/>
      <c_Footer/>
    </v-main>
  </v-app>
</template>

<script>
// import cHeader from "../components/c_Header.vue"
import c_Proizvodstvo from "@/components/c_Proizvodstvo.vue";
import c_Products from "@/components/c_Products.vue";
import c_Banner from "@/components/c_Banner.vue";
import c_Contact from "@/components/c_Contact.vue";
import c_Footer from "@/components/c_Footer.vue";
export default {
  name: 'HomeView',
  components: {
    // cHeader,
    c_Proizvodstvo,
    c_Products,
    c_Banner,
    c_Contact,
    c_Footer
  }
}
</script>
