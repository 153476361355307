<template>
  <footer class="team footer-copy">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
          <p>
            2024 &copy; BIOMED INDUSTRY, ВСЕ ПРАВА ЗАЩИЩЕНЫ
            <!-- <a href="http://w3Template.com" target="_blank" rel="dofollow"
              >W3 Template</a
            > -->
          </p>
        </div>
      </div>
    </div>
  </footer>
</template>

<style src='../css/style.css'>
</style>