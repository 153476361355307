// src/http.js
import axios from 'axios';
import store from './store';  // Vuex store'ni import qilamiz
import jwt_decode from 'jwt-decode';

const http = axios.create({
    baseURL: "http://127.0.0.1:8000",
//   baseURL: process.env.VUE_APP_API_URL,
  headers: {
    'Content-Type': 'application/json'
  }
});

// Request interceptor
http.interceptors.request.use(async config => {
  const token = store.getters.getAccessToken;
  const refreshToken = store.getters.getRefreshToken;
  
  if (token && TokenExpired(token)) {  // TokenExpired — bu tokenning muddati o'tganligini tekshiruvchi funksiya
    try {
      const newToken = await store.dispatch('refreshToken', refreshToken);
      config.headers['Authorization'] = `Bearer ${newToken}`;
    } catch (error) {
      console.error('Unable to refresh token:', error);
    }
  } else {
    config.headers['Authorization'] = `Bearer ${token}`;
  }
  
  return config;
}, error => {
  return Promise.reject(error);
});

function TokenExpired(token) {
  const decoded = jwt_decode(token);  // jwt-decode kutubxonasidan foydalaniladi
  return decoded.exp < Date.now() / 1000;
}

export default http;
