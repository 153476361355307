import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import 'vuetify/styles'
import { createVuetify } from 'vuetify'

import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
import '@mdi/font/css/materialdesignicons.css'
import 'animate.css';
// import './js/scrollIt.min.js'
// import bootstrap from 'bootstrap'
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap"

// Google Analytics tracking ID
// const trackingID = 'UA-XXXXX-Y';

const vuetify = createVuetify({
  components,
  directives,
})

createApp(App).use(store).use(
//   VueAnalytics, {
//   id: trackingID,
//   router
// }
router
).use(vuetify).mount('#app')
