import { createStore } from 'vuex'
// import axios from "axios";
import http from '@/http';
import router from '@/router'


export default createStore({
  state: {
    isAuthenticated: false,
    accessToken: localStorage.getItem('accessToken') || '',
    refreshToken: localStorage.getItem('refreshToken') || '',
    data: [],
    products: [
      // Product AVITSERON
      // ID : 1
      {
        id: 1,
        router_name: 'Bacillus clausii',
        // name: ['Bacillus clausii', 'Авицерон', 'Avitseron'],
        name: 'Bacillus clausii',
        cost: '',
        title: [
          "Immunitetning og'ishlarini tabiiy ravishda tuzatish",
          'Естественная коррекция отклонений иммунитета',
          'Natural correction of immunity developments'
        ],
        image: '/products/1280x1010/Avitseron_1280x1010.jpg',
        link: '/singleproduct',
        category: 'energy',
        type: ['Kapsula', 'Капсула', 'Capsule'],
        type_index: 0,
        view: [
          'Chiqarilish shakli: Kapsula, 30 dona',
          'Форма выпуска: Капсула, по 30 штук в упаковке',
          'Release form: Capsule, 30 pieces per pack'
        ],
        children_image: [
          {
            id: 1,
            image: '/products/1280x1010/Avitseron_1280x1010.jpg'
          }
        ],
        subtitle: [
          "Avitseron organizmning maxsus himoya mexanizmlarini mustahkamlaydi, immun tizimining zaiflashishi bilan kechadigan turli surunkali kasalliklarni kompleks davolashda foydalanish tavsiya etiladi. Kimyoterapiya va radiatsiya terapiyasini o'tkazgan bemorlarning imunitetini qayta tiklash va ish faoliyatini yaxshilashiga yordam beradi.\n Avitseron - gumoral va kuchli faollashtiruvchi hujayra immuniteti, shuningdek, organizmning turli etiologiyalarning yuqumli kasalliklariga chidamliligini oshirishga yordam beradigan fagotsitozdir",
          '«АВИЦЕРОН» стимулирует собственные защитные механизмы организма, рекомендуется применять в комплексной терапии различных хронических заболеваний, сопровождающихся ослаблением функций иммунитета. Способствует восстановлению и улучшению показателей иммунитета у больных после перенесенной химиотерапии и лучевой терапии.\n «АВИЦЕРОН» является мощным активатором гуморального и клеточного звена иммунитета, а также фагоцитоза, что способствует повышению сопротивляемости организма к инфекционным заболеваниям различной этиологии',
          ''],
        // Kereligi
        indication: [
          {
            id: 0,
            data: [
              "Surunkali gepatit (B, C)",
              "Urogenital infektsiyalar",
              "Urogenital infektsiyalar",
              "VICH - infektsiyasi",
              "Kimyoterapiya olishdan oldin",
              "SARS va grippning oldini olish",
              "Surunkali brutsellyoz",
              "Surunkali: xoletsistit, gastrit, kolit",
              "Surunkali bronxit (allergik komponentsiz)",
            ]
          },
          {
            id: 1,
            data: [
              'Хронические гепатиты (B, С)',
              'Урогенитальные инфекции',
              'TORCH инфекции',
              'ВИЧ - инфекция',
              'Перед получением химиотерапии',
              'Профилактика ОРВИ и гриппа',
              'Хронический бруцеллёз',
              'Хроническом: холецистите, гастрите, колите',
              'Хронический бронхит (без аллергического компонента)'
            ]
          },
          {
            id: 2,
            data: ['']
          }
        ],
        // Tarkibi
        composition: [
          //Uzbek language
          {
            id: 0,
            items: [
              { Tarkibi: "To'qimalarning gidrolizati", Miqdori: '1 mg' },
              { Tarkibi: "S Vitamini", Miqdori: '25 mg' },
              { Tarkibi: "Mentol", Miqdori: '5 mg' },
            ]
          },
          //Russian language
          {
            id: 1,
            items: [
              { Состав: 'Тканевой гидролизат', Экстракт: '1 мг' },
              { Состав: 'Аскорбиновая кислота', Экстракт: '25 мг' },
              { Состав: 'Ментол', Экстракт: '5 мг' },
            ]
          },
          //English language
          {
            id: 2,
            items: []
          }
        ],
        // Ishlatishi
        useway: [
          {
            //Uzbek language
            id: 0,
            data: [
              "Suv bilan ichga qabul qilish orqali qo'llaniladi",
              "Ovqatlanish vaqtida yoki ovqatlanishdan so'ng",
              "Kattalar : 1-2 kapsuladan kuniga 2 maxal",
              "5 yoshdan katta bolalar : 1 kapsuladan kuniga 1 maxal",
              "Qabul qilish muddati : 60 kun",
              "Giperatsid gastriti bor bemorlarga ehtiyotkorlik bilan qo'llash tavsiya etiladi",
              "Maxsus ko'rsatmalar: homilador ayollarda foydalanish bo'yicha ma'lumotlar yo'q"
            ],

            contraindications: [
              "Allergik reaktsiyalar va bronxial astma kasalliklarining o'tkir davrida foydalanish tavsiya etilmaydi",
              "Miyokardning jiddiy shikastlanishida tavsiya etilmaydi",
              "Preparat komponentlariga shahsiy o'zlashtiraolmaslik"
            ]
          },
          {
            //Russian language
            id: 1,
            data: [
              'Приём внутрь, во время или после еды, запивая стаканом воды',
              'Взрослым: по 1 - 2 капсуле 2 раза в день',
              'Детям с 5 лет: по 1 капсуле 1 раз в день',
              'Длительность приёма: 60 дней',
              'С осторожностью: при гиперацидном гастрите',
              'Особые указания: нет данных о применении у беременных'
            ],

            contraindications: [
              'не рекомендуется применять в остром периоде заболевания, при острых аллергических реакциях и бронхиальной астме',
              'При тяжелых поражениях миокарда',
              'Индивидуальная непереносимость к компонентам препарата'
            ]
          },
          {
            //English language
            id: 2,
            data: [],

            contraindications: []
          }
        ]
      },

      // Product SINK SSD3
      // ID : 2
      {
        id: 2,
        router_name: 'Sink_SSD3',
        name: ['Sink S.S.D3', 'Цинк С.С.Д3', 'Sink S.S.D3'],
        cost: '',
        title: [
          'Immunitet uchun muhim vitaminlar va minerallar',
          'Важные витамины и минералы для поддержки иммунной системы',
          'Важные витамины и минералы для поддержки иммунной системы'
        ],
        image: '/products/1280x1010/SinkSDD_Gray_1280x1010.jpg',
        link: '/singleproduct',
        category: 'energy',
        type: ['Tabletka', 'Таблетка', 'Tablet'],
        type_index: 1,
        view: [
          'Chiqarilish shakli: Tabletka, 30 dona',
          'Форма выпуска: Таблетки, по 30 штук в упаковке',
          'Release form: Tablets, 30 pieces per pack'
        ],
        children_image: [
          {
            id: 1,
            image: '/products/1280x1010/SinkSDD_Gray_1280x1010.jpg'
          },
          {
            id: 1,
            image: '/products/photostudio/b_Sink_front.jpeg'
          },
          {
            id: 2,
            image: '/products/photostudio/b_Sink_left.jpeg'
          },
          {
            id: 3,
            image: '/products/photostudio/b_Sink_2.jpeg'
          }
        ],
        subtitle: [
          "Sink SSD3 - immun tizimini mustaxkamlaydi, turli xil infeksion kasalliklarga, hamda grip va o'tkir respirator virusli infeksiyalarga qarshi kurashuvchanligini oshiradi. Kuchli anioksidant, kollagen sintezlanishida ishtirok etadi, insulin sekresiyasini kuchaytiradi, xolesterin metabolizmini meyorlashtiradi. Sink SDD3 - steroid gormonlarni sintezlanishida ishtirok etadi, qarilik jarayonini susaytiradi, V gurux vitaminlarini o'zlashtirilishini yaxshilaydi, Tomir devorlarini mustahkamlaydi, qon tozalash xususiyatiga ega, erkin radikallarni bartaraf etadi, uglevod va oqsil almashinuvini faollashtiradi.",
          'Цинк ССД3 - способствует укреплению иммунной системы, повышает сопротивляемость к инфекциям различной этиологии, в том числе гриппа, острых респираторных вирусных инфекций. Оказывает мощный антиоксидантный эффект, участвует в синтезе коллагена, стимулирует секрецию инсулина, нормализует метаболизм холестерина. Цинк ССД3 -  участвует в синтезе стероидных гормонов, замедляет процессы старения, способствует улучшению усвоения витаминов группы В. Укрепляет стенки сосудов, способствует очищению крови и устранению свободных радикалов, активирует углеводный и белковый обмен',
          ''
        ],
        // Kereligi
        indication: [
          {
            id: 0,
            data: [
              'Immunitet mustahkamlashga, hamda turli shamollash va virusli kasalliklar rivojlanish xavfini kamaytirish uchun',
              'Antioksidant ximoya',
              'Teri, soch va tirnoqlar salomatligi uchun',
              "Yurak-qon tomir tizimini faoliyatini sog'lom saqlashda va organizmning reproduktiv hususiyatini qo'llab quvatlashishda"
            ]
          },
          {
            id: 1,
            data: [
              'Укрепления иммунитета и снижения риска развития простудных и вирусных заболеваний',
              'Антиоксидантная защита',
              'Для красоты кожи, волос и ногтей',
              'Поддержания здоровья сердечно-сосудистой системы и репродуктивной функции организма'
            ]
          }
        ],
        // Tarkibi
        composition: [
          //Uzbek language
          {
            id: 0,
            items: [
              { Tarkibi: 'Sink laktati', Miqdori: '30mg' },
              { Tarkibi: 'D3 vitamini', Miqdori: '400ME' },
              { Tarkibi: 'C vitamini ', Miqdori: '50mg' },
              { Tarkibi: 'Selen', Miqdori: '37mkg' }
            ]
          },
          //Russian language
          {
            id: 1,
            items: [
              { Состав: 'Цинк лактат', Экстракт: '30 мг' },
              { Состав: 'Витамин Д3', Экстракт: '400 МЕ' },
              { Состав: 'Витамин С', Экстракт: '50 мг' },
              { Состав: 'Селен', Экстракт: '37 мкг' }
            ]
          },
          //English language
          {
            id: 2,
            items: [
              { Composition: 'Цинк лактат', Extract: '30 mg' },
              { Composition: 'Vitamin D3', Extract: '400 МЕ' },
              { Composition: 'Vitamin С', Extract: '50 mg' },
              { Composition: 'Селен', Extract: '37 mkg' }
            ]
          }
        ],
        // Ishlatishi
        useway: [
          {
            //Uzbek language
            id: 0,
            data: [
              "Ovqatlanish vaqtida ichga qabul qilish orqali qo'llaniladi",
              "Kattalarga va 16 yoshdan oshgan o'smirlarga: 1-2 tabletkadan kuniga 2-3 maxal",
              "3 yoshdan 16 yoshgacha bo'lgan bolalarga: 0,5 - 1 tabletkadan kuniga 1-2 maxal",
              'Berilish davomiyligi : 10 kundan 2 oygacha'
            ],

            contraindications: [
              "Preparat komponentlariga shahsiy o'zlashtiraolmaslik",
              "Boshqa dorilar bilan o'zaro ta'siri: polivitaminlar bilan birgalikda qabul qilish tavsiya etilmaydi",
              "Homiladorlik va emizish davrida qo'llanilishi: homiladorlik va emizish davrida berilishi haqida ma'lumot yo'q"
            ]
          },
          {
            //Russian language
            id: 1,
            data: [
              'Назначается внутрь во время еды',
              'Взрослым и детям старше 16 лет: по 1 - 2 таблетке, 2 - 3 раза в день',
              'Детям с 3 до 16 лет: по 0,5 – 1 таблетке, 1 - 2 раза в день',
              'Длительность приема: от 10 дней до 2 месяцев'
            ],

            contraindications: [
              'Индивидуальная непереносимость компонентов препарата',
              'Взаимодействие с другими лекарственными средствами: не рекомендуется одновременный приём поливитаминных комплексов',
              'Применение при беременности и в период лактации: нет данных о применении в период беременности и лактации'
            ]
          },
          {
            //English language
            id: 2,
            data: [
              'Назначается внутрь во время еды',
              'Взрослым и детям старше 16 лет: по 1 - 2 таблетке, 2 - 3 раза в день',
              'Детям с 3 до 16 лет: по 0,5 – 1 таблетке, 1 - 2 раза в день',
              'Длительность приема: от 10 дней до 2 месяцев'
            ],

            contraindications: [
              'Индивидуальная непереносимость компонентов препарата',
              'Взаимодействие с другими лекарственными средствами: не рекомендуется одновременный приём поливитаминных комплексов',
              'Применение при беременности и в период лактации: нет данных о применении в период беременности и лактации'
            ]
          }
        ]
      },
    ]
  },
  mutations: {
    setAccessToken(state, accessToken) {
      state.accessToken = accessToken;
      localStorage.setItem('accessToken', accessToken);
    },
    setRefreshToken(state, refreshToken) {
      state.refreshToken = refreshToken;
      localStorage.setItem('refreshToken', refreshToken);
    },
    clearTokens(state) {
      state.accessToken = '';
      state.refreshToken = '';
      localStorage.removeItem('accessToken');
      localStorage.removeItem('refreshToken');
    },
    logout(state) {
      state.token = '';
      state.refreshToken = '';
      localStorage.removeItem('token');
      localStorage.removeItem('refreshToken');
    }
  },

  actions: {
    login({ commit }, { username, password }) {
      console.log("store action function kirdi")

      return http.post('/token', { username, password })
        .then(response => {
          if (response.data.accessToken && response.data.refreshToken) {
            commit('setAccessToken', response.data.accessToken);
            commit('setRefreshToken', response.data.refreshToken);
            return response.data;
          } else {
            throw new Error('Tokens are missing in the response');
          }
        });
    },
    refreshToken({ commit }, refreshToken) {
      return new Promise((resolve, reject) => {
        http.post('/refresh', { refreshToken })
          .then(response => {
            if (response.data.accessToken) {
              commit('setAccessToken', response.data.accessToken);
              resolve(response.data.accessToken);
            } else {
              throw new Error('No new token provided');
            }
          })
          .catch(error => {
            console.error('Error refreshing token:', error);
            commit('clearTokens');
            router.replace('/login');  // Foydalanuvchini login sahifasiga yo'naltirish
            reject(error);
          });
      });
    },
    logout({ commit }) {
      commit('logout');
    },
    fetchData({ commit }) {
      return http.get('/data')  // http instansidan foydalanish
        .then(response => {
          commit('setData', response.data);  // Ma'lumotlarni state'ga saqlash
        })
        .catch(error => {
          console.error('Error fetching data:', error);
        });
    }

  },
  getters: {
    isAuthenticated: state => state.isAuthenticated,
    isLoggedIn: state => !!state.accessToken,
    getAccessToken: state => state.accessToken,
    getRefreshToken: state => state.refreshToken
  }
})


// export default store;

