<!-- LoginPage.vue -->
<template>
  <div class="login-page">
    <form @submit.prevent="login">
      <h2>Login</h2>
      <input type="text" v-model="username" placeholder="Username" required />
      <input
        type="password"
        v-model="password"
        placeholder="Password"
        required
      />
      <!-- <button type="submit">Login</button> -->
      <button @click="test">Login</button>
    </form>
    <button @click="test">test</button>
  </div>
</template>
  
  <script>
import axios from "axios";
import { mapActions } from "vuex";
// import { login } from "@/http";

export default {
  data() {
    return {
      username: "",
      password: "",
      error: "",
    };
  },
  methods: {
    ...mapActions(["login_store"]),
    // ...mapActions(["login"]),

    async login_1() {
      try {
        const response = await fetch("http://127.0.0.1:8000/api/login", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            username: this.username,
            password: this.password,
          }),
        });

        if (response.ok) {
          const data = await response.json();
          // Assuming the server responds with a token
          const token = data.token;
          // Store the token in local storage for future use
          localStorage.setItem("token", token);
          // Redirect to the dashboard page
          this.$router.push("/dashboard");
        } else {
          // Handle login failure (e.g., display error message)
          console.error("Login failed");
        }
      } catch (error) {
        console.error("Error during login:", error);
      }
    },
    async login_2() {
      try {
        const response = await axios.post("/login", {
          username: this.username,
          password: this.password,
        });
        // Redirect user to dashboard or perform other actions on successful login
        console.log("Login successful", response.data);
        console.log("Response : ", response);
        if (response.status === 200) {
          const data = await response.data;
          // Assuming the server responds with a token
          const token = data.token;
          // Store the token in local storage for future use
          localStorage.setItem("token", token);
          // Redirect to the dashboard page
          this.$router.push("/dashboard");
        } else {
          // Handle login failure (e.g., display error message)
          console.error("Login failed");
        }
      } catch (error) {
        // Handle login error
        this.error = "Invalid username or password";
        console.error("Login failed", error);
      }
    },
    login_3() {
      axios
        .post("http://127.0.0.1:8000/login", {
          username: this.username,
          password: this.password,
        })
        .then((response) => {
          // Assuming the response contains token and refreshToken
          const { token, refreshToken } = response.data;
          // Save token and refreshToken to Vuex store
          this.$store.dispatch("login", { token, refreshToken });
          // Redirect to dashboard
          this.$router.push({ name: "Dashboard" });
        })
        .catch((error) => {
          // Handle login error
          console.error("Login failed:", error);
          // Optionally display an error message to the user
        });
    },
    async login_4() {
      try {
        await this.login_store({
          username: this.username,
          password: this.password,
        });
        // Redirect to dashboard upon successful login
        this.$router.push("/dashboard");
      } catch (error) {
        console.error("Login failed:", error);
        // Handle login error, show error message, etc.
      }
    },
    login_func() {
      try {
        console.log("login function kirdi1");

        // const response =  await this.login({
        //   username: this.username,
        //   password: this.password,
        // });

        // console.log("response: ", response);

        // .then(() => {
        //   console.log("dashboard kirdi");
        //   this.$router.push({ name: "dashboard" });
        // })
        // .catch((error) => {
        //   console.error("Login error:", error);
        //   alert("Login failed");
        // });

        const url = "http://127.0.0.1:8000/"; // Backend server URL
        // const url = "http://localhost:8000/"; // Backend server URL
        // const credentials = {
        //   username: this.username,
        //   password: this.password,
        // };

        axios
          .post(url)
          .then((response) => {
            console.log("Token:", response);
            // console.log("Token:", response.data.access_token);
            // Tokenni saqlash, navigatsiya qilish yoki boshqa amallar

          })
          .catch((error) => {
            console.error("Login error:", error);
          });
      } catch (error) {
        console.error("Login failed:", error);
        // Handle login error, show error message, etc.
      }
    },
    async test(){
      await axios.get("http://127.0.0.1:8000/users")
      .then((response) => {console.log(response)})
      .catch((error) => {console.log("Errorku: ", error)})
    }
  },
};
</script>
  
<style scoped>
.login-page {
  font-family: Arial, sans-serif;
  background-color: #f0f0f0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
form {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  width: 300px;
}
input[type="text"],
input[type="password"],
button {
  width: 100%;
  padding: 10px;
  margin: 5px 0;
  box-sizing: border-box;
  border: 1px solid #ccc;
  border-radius: 4px;
}
button {
  background-color: #4caf50;
  color: white;
  border: none;
  cursor: pointer;
}
button:hover {
  background-color: #45a049;
}
</style>
  