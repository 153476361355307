<template>
  <section class="team section-padding" data-scroll-index="2" id="products">
    <v-container>
      <v-row>
        <div class="col-md-12">
          <div class="sectioner-header text-center">
            <h2 class="text-h4 text-lg-h3">Наша продукция</h2>
            <span class="line"></span>
            <p>
              Мы предлагаем широкий ассортимент дисков для антибиотикограммы, с
              различными антибиотиками и концентрациями, удовлетворяющих
              потребности современных лабораторий и клиник. Наши диски помогают
               специалистам быстро и точно определять
              чувствительность микроорганизмов к антибиотикам, что способствует
              эффективному лечению пациентов. 

              
            </p>
            <h4>Диски с антибиотиками фасуются во флаконах №100</h4>
          </div>
        </div>

        <v-col cols="12">
          <v-text-field
            v-model="search"
            width="300px"
            label="Поиск продуктов..."
            class="mb-2 mt-2 form-input w-100"
            variant="outlined"
          ></v-text-field>

          
        </v-col>

        <v-col cols="12" md="4" v-for="product in filteredProducts" :key="product.name">
          <v-card class="mx-auto" :title="product.name">
            <template v-slot:prepend>
              <v-avatar color="blue-darken-2">
                <v-icon icon="mdi-widgets"></v-icon>
              </v-avatar>
            </template>
            <template v-slot:append>
              <!-- <v-avatar size="24">
                <v-img
                  alt="John"
                  src="https://cdn.vuetifyjs.com/images/john.png"
                ></v-img>
              </v-avatar> -->
              <v-checkbox
                v-model="product.selected"
                @change="toggleSelect(product)"
              ></v-checkbox>
            </template>
            <v-card-text>
              <v-list-item class="mt-0 pt-0">
                <v-list-item-content>
                  <v-list-item-title>
                    <strong>Lot :</strong> {{ product.lot }}
                  </v-list-item-title>
                  <v-list-item-title>
                    <strong>Код:</strong> {{ product.kod }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-text-field
                v-if="product.selected"
                v-model="product.quantity"
                type="number"
                min="1"
                label="Количество (флакон)"
              ></v-text-field>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <v-row justify="center">
        <v-col cols="auto">
          <btn width="200" @click="openDialog" class="mt-5 about-btn">
            Заказать
          </btn>
        </v-col>
      </v-row>
    </v-container>

    <v-dialog v-model="dialog" max-width="500px">
      <v-card>
        <form
          ref="form"
          role="form"
          method="POST"
          id="order_form"
          @submit.prevent="submitDialog"
        >
          <v-card-title>
            <span class="headline">Введите свои данные</span>
          </v-card-title>
          <v-card-text>
            <v-text-field
              v-model="user.name"
              label="Имя-фамилия"
              :rules="[(v) => !!v || 'Требуется имя фамилия']"
              required
            ></v-text-field>
            <v-text-field
              v-model="user.email"
              label="Email"
              :rules="[
                (v) => !!v || 'Требуется электронная почта',
                (v) => /.+@.+/.test(v) || 'E-mail must be valid',
              ]"
              required
              type="email"
            ></v-text-field>
            <v-text-field
              v-model="user.phone"
              label="Номер телефона"
              :rules="[(v) => !!v || 'Требуется номер телефона']"
              required
              type="tel"
            ></v-text-field>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <!-- <v-btn color="primary" text @click="submitDialog" type="submit">Submit</v-btn> -->
            <v-btn color="primary" text type="submit">Отправить</v-btn>
          </v-card-actions>
        </form>
      </v-card>
    </v-dialog>
  </section>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      products: [
        {
          name: "Amikacin (30 mcg)",
          lot: "1",
          unit: "30 mcg",
          kod: "AK",
          selected: false,
          quantity: 1,
        },
        {
          name: "Amoxycillin (25 mcg)",
          lot: "2",
          unit: "30 mcg",
          kod: "AMX",
          selected: false,
          quantity: 1,
        },

        {
          name: "Amoxycillin/Clavulanic acid (R) (20/10 mcg)",
          lot: "3",
          unit: "30 mcg",
          kod: "AMC",
          selected: false,
          quantity: 1,
        },
        {
          name: "Ampicillin (10 mcg)",
          lot: "6",
          unit: "30 mcg",
          kod: "AMP",
          selected: false,
          quantity: 1,
        },
        {
          name: "Azithromycin (15 mcg)",
          lot: "9",
          unit: "30 mcg",
          kod: "AT",
          selected: false,
          quantity: 1,
        },
        {
          name: "Cefazolin (30 mcg)",
          lot: "16",
          unit: "30 mcg",
          kod: "CZ",
          selected: false,
          quantity: 1,
        },
        {
          name: "Cefepime (30 mcg)",
          lot: "18",
          unit: "30 mcg",
          kod: "CPM",
          selected: false,
          quantity: 1,
        },
        {
          name: "Cefixime (5 mcg)",
          lot: "20",
          unit: "30 mcg",
          kod: "CFM",
          selected: false,
          quantity: 1,
        },
        {
          name: "Cefoperazone (75 mcg)",
          lot: "23",
          unit: "30 mcg",
          kod: "CFP",
          selected: false,
          quantity: 1,
        },
        {
          name: "Cefoperazone/Sulbactam (75/10 mcg)",
          lot: "24",
          unit: "30 mcg",
          kod: "CSF",
          selected: false,
          quantity: 1,
        },
        {
          name: "Cefpodoxime (10 mcg)",
          lot: "27",
          unit: "30 mcg",
          kod: "CPD",
          selected: false,
          quantity: 1,
        },
        {
          name: "Ceftazidime (10 mcg)",
          lot: "30",
          unit: "30 mcg",
          kod: "CTZ",
          selected: false,
          quantity: 1,
        },
        {
          name: "Ceftazidime (30 mcg)",
          lot: "31",
          unit: "30 mcg",
          kod: "CAZ",
          selected: false,
          quantity: 1,
        },
        {
          name: "Ceftriaxone (30 mcg)",
          lot: "35",
          unit: "30 mcg",
          kod: "CTR",
          selected: false,
          quantity: 1,
        },
        {
          name: "Cefuroxime (30 mcg)",
          lot: "38",
          unit: "30 mcg",
          kod: "CXM",
          selected: false,
          quantity: 1,
        },
        {
          name: "Cephotaxime (30 mcg)",
          lot: "43",
          unit: "30 mcg",
          kod: "CTX",
          selected: false,
          quantity: 1,
        },
        {
          name: "Chloramphenicol(30 mcg)",
          lot: "47",
          unit: "30 mcg",
          kod: "C",
          selected: false,
          quantity: 1,
        },
        {
          name: "Ciprofloxacin (5 mcg)",
          lot: "50",
          unit: "30 mcg",
          kod: "CIP",
          selected: false,
          quantity: 1,
        },
        {
          name: "Clarithromycin (15 mcg)",
          lot: "51",
          unit: "30 mcg",
          kod: "CL",
          selected: false,
          quantity: 1,
        },
        {
          name: "Clindamycin (2 mcg)",
          lot: "52",
          unit: "30 mcg",
          kod: "CD",
          selected: false,
          quantity: 1,
        },
        {
          name: "Clotrimazole (10 mcg)",
          lot: "53",
          unit: "30 mcg",
          kod: "CTM",
          selected: false,
          quantity: 1,
        },
        {
          name: "Doxycycline Hydrochloride (30 mcg)",
          lot: "59",
          unit: "30 mcg",
          kod: "DO",
          selected: false,
          quantity: 1,
        },
        {
          name: "Erythromycin (15 mcg)",
          lot: "63",
          unit: "30 mcg",
          kod: "E",
          selected: false,
          quantity: 1,
        },
        {
          name: "Fluconazole (25 mcg)",
          lot: "65",
          unit: "30 mcg",
          kod: "FLC",
          selected: false,
          quantity: 1,
        },
        {
          name: "Fosfomycin (200 mcg)",
          lot: "66",
          unit: "30 mcg",
          kod: "FOS",
          selected: false,
          quantity: 1,
        },
        {
          name: "Furazolidone (50 mcg)",
          lot: "67",
          unit: "30 mcg",
          kod: "FR",
          selected: false,
          quantity: 1,
        },
        {
          name: "Gatifloxacin (5 mcg)",
          lot: "69",
          unit: "30 mcg",
          kod: "GAT",
          selected: false,
          quantity: 1,
        },
        {
          name: "Gentamicin (10 mcg)",
          lot: "70",
          unit: "30 mcg",
          kod: "GEN",
          selected: false,
          quantity: 1,
        },
        {
          name: "Gentamicin (120 mcg)",
          lot: "71",
          unit: "30 mcg",
          kod: "HLG",
          selected: false,
          quantity: 1,
        },
        {
          name: "Imipenem/(10 mcg)",
          lot: "72",
          unit: "30 mcg",
          kod: "IPM",
          selected: false,
          quantity: 1,
        },
        {
          name: "Itraconazole (10 mcg)",
          lot: "75",
          unit: "30 mcg",
          kod: "IT",
          selected: false,
          quantity: 1,
        },
        {
          name: "Kanamycin (30 mcg)",
          lot: "76",
          unit: "30 mcg",
          kod: "K",
          selected: false,
          quantity: 1,
        },
        {
          name: "Levofloxacin (5 mcg)",
          lot: "78",
          unit: "30 mcg",
          kod: "LE",
          selected: false,
          quantity: 1,
        },
        {
          name: "Lincomycin (15 mcg)",
          lot: "79",
          unit: "30 mcg",
          kod: "L",
          selected: false,
          quantity: 1,
        },
        {
          name: "Linezolid (30 mcg)",
          lot: "80",
          unit: "30 mcg",
          kod: "LZ",
          selected: false,
          quantity: 1,
        },
        {
          name: "Meropenem (10 mcg)",
          lot: "84",
          unit: "30 mcg",
          kod: "MRP",
          selected: false,
          quantity: 1,
        },
        {
          name: "Metronidazole (5 mcg)",
          lot: "88",
          unit: "30 mcg",
          kod: "MTR",
          selected: false,
          quantity: 1,
        },
        {
          name: "Nitroxoline (30 mcg)",
          lot: "100",
          unit: "30 mcg",
          kod: "NTX",
          selected: false,
          quantity: 1,
        },
        {
          name: "Norfloxacin (10 mcg)",
          lot: "101",
          unit: "30 mcg",
          kod: "NX",
          selected: false,
          quantity: 1,
        },
        {
          name: "Nystatin (100 u)",
          lot: "103",
          unit: "30 mcg",
          kod: "NS",
          selected: false,
          quantity: 1,
        },
        {
          name: "Ofloxacin (5 mcg)",
          lot: "104",
          unit: "30 mcg",
          kod: "OF",
          selected: false,
          quantity: 1,
        },
        {
          name: "Pefloxacin (5 mcg)",
          lot: "108",
          unit: "30 mcg",
          kod: "PF",
          selected: false,
          quantity: 1,
        },
        {
          name: "Penicillin-G (10 units)",
          lot: "109",
          unit: "30 mcg",
          kod: "P",
          selected: false,
          quantity: 1,
        },
        {
          name: "Piperacillin/Tazobactam -100/10",
          lot: "112",
          unit: "30 mcg",
          kod: "TZP",
          selected: false,
          quantity: 1,
        },
        {
          name: "Rifampicin (5 mcg)",
          lot: "115",
          unit: "30 mcg",
          kod: "RA",
          selected: false,
          quantity: 1,
        },
        {
          name: "Roxithromycin (30 mcg)",
          lot: "116",
          unit: "30 mcg",
          kod: "ROX",
          selected: false,
          quantity: 1,
        },
        {
          name: "Spiramycin (100 u)",
          lot: "120",
          unit: "30 mcg",
          kod: "SP",
          selected: false,
          quantity: 1,
        },
        {
          name: "Streptomycin (10 mcg)",
          lot: "122",
          unit: "30 mcg",
          kod: "STR",
          selected: false,
          quantity: 1,
        },
        {
          name: "Streptomycin (300 mcg)",
          lot: "123",
          unit: "30 mcg",
          kod: "HLS",
          selected: false,
          quantity: 1,
        },
        {
          name: "Tetracycline (30 mcg)",
          lot: "131",
          unit: "30 mcg",
          kod: "TEI",
          selected: false,
          quantity: 1,
        },
        {
          name: "Tobramycin (10 mcg)",
          lot: "135",
          unit: "30 mcg",
          kod: "TOB",
          selected: false,
          quantity: 1,
        },
        {
          name: "Vancomycin (30 mcg)",
          lot: "138",
          unit: "30 mcg",
          kod: "VA",
          selected: false,
          quantity: 1,
        },
        {
          name: "Voriconazole (1 mcg)",
          lot: "140",
          unit: "30 mcg",
          kod: "VRC",
          selected: false,
          quantity: 1,
        },
        {
          name: "Ampicillin/Oxacillin (10/1)",
          lot: "141",
          unit: "30 mcg",
          kod: "AMO",
          selected: false,
          quantity: 1,
        },
      ],
      dialog: false,
      user: {
        name: "",
        email: "",
        phone: "",
      },
      token: "6504644931:AAHB1qPIuMl9xCJNOy0FdraYTJOT86CcQ3g",
      chatId: "320968728",
      search: "",
    };
  },
  computed: {
    filteredProducts() {
      if (!this.search) {
        return this.products;
      }
      const searchLower = this.search.toLowerCase();
      return this.products.filter(
        (product) =>
          product.name.toLowerCase().includes(searchLower) ||
          product.lot.toLowerCase().includes(searchLower) ||
          product.unit.toLowerCase().includes(searchLower)
      );
    },
  },
  methods: {
    getNow() {
      const today = new Date();
      const date =
        today.getFullYear() +
        "-" +
        (today.getMonth() + 1) +
        "-" +
        today.getDate();
      const time =
        today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
      const dateTime = date + " " + time;

      return dateTime;
    },
    toggleSelect(product) {
      if (!product.selected) {
        product.quantity = 1; // O'chirilganda miqdorni reset qiladi
      }
    },
    openDialog() {
      const selectedProducts = this.products.filter(
        (product) => product.selected
      );
      if (selectedProducts.length === 0) {
        alert("Сначала выберите препараты!");
        return;
      }
      this.dialog = true;
    },
    submitDialog() {
      this.dialog = false;
      this.submitSelected();
    },

    submitSelected() {
      const selectedProducts = this.products.filter(
        (product) => product.selected
      );
      const payload = selectedProducts.map((product) => ({
        name: product.name,
        lot: product.lot,
        kod: product.kod,
        quantity: product.quantity,
      }));

      let text = "[ABSD - ZAKAZ " + this.getNow() + "]";
      text += `\nДанные пользователя:\nName: ${this.user.name}\nEmail: ${this.user.email}\nPhone: ${this.user.phone}\n\nSelected Products:\n`;
      payload.forEach((product) => {
        text += `Названия: ${product.name}\nЛот: ${product.lot}\nКод: ${product.kod}\nКоличество: ${product.quantity}\n\n`;
      });

      axios
        .post(`https://api.telegram.org/bot${this.token}/sendMessage`, {
          chat_id: this.chatId,
          text: text,
        })
        .then(
          (response) => {
            console.log("Successfully!", response);
            alert(
              "✅ Отправлена! Мы свяжемся с вами в ближайшее время по поводу вашей заявки!"
            );
            this.clearForm();
          },
          (error) => {
            console.log(error);
            alert("Проблема отправки сообщения");
          }
        );
    },

    clearForm() {
      this.products.forEach((product) => {
        product.selected = false;
        product.quantity = 1;
      });
      this.user.name = "";
      this.user.email = "";
      this.user.phone = "";
    },
  },
};
</script>

<style>
@import "~vuetify/dist/vuetify.min.css";
</style>
