<template>
  <section class="contact section-padding" data-scroll-index="3" id="contact">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <div class="sectioner-header text-center">
            <h3 class="text-lg-h3 text-h4">
              Оставьте заявку и получите ответы на свои вопросы!
            </h3>
            <span class="line"></span>
            <p>Мы свяжемся с вами в ближайшее время по поводу вашей заявки!</p>
          </div>
          <div class="section-content">
            <div class="row">
              <div class="col-sm-12 col-md-12 col-lg-8">
                <form
                  id="contact_form"
                  role="form"
                  method="POST"
                  @submit.prevent="submit"
                >
                  <div class="row">
                    <div class="col">
                      <input
                        type="text"
                        id="your_name"
                        class="form-input w-100"
                        name="full-name"
                        placeholder="Имя-фамилия"
                        v-model="name"
                        required
                      />
                    </div>

                    <div class="col">
                      <input
                        type="number"
                        id="email"
                        class="form-input w-100"
                        name="phone"
                        placeholder="Номер телефона"
                        v-model="phone"
                        required
                      />
                      <!-- pattern="[0-9]{3}[0-9]{2}[0-9]{3}" -->
                    </div>
                  </div>
                  <br />
                  <!-- <input
                    type="text"
                    id="subject"
                    class="form-input w-100"
                    name="subject"
                    placeholder="Subject"
                  /> -->
                  <textarea
                    class="form-input w-100"
                    id="message"
                    placeholder="Message"
                    name="message"
                    v-model="message"
                  ></textarea>
                  <button
                    class="btn-grad w-100 text-uppercase text-white"
                    type="submit"
                    name="button"
                  >
                    Оставить заявку
                  </button>
                </form>
              </div>
              <div class="col-sm-12 col-md-12 col-lg-4">
                <div class="contact-info white">
                  <div class="contact-item media">
                    <i
                      class="fa fa-map-marker-alt media-left media-right-margin"
                    ></i>
                    <div class="media-body">
                      <p class="text-uppercase">Адрес</p>
                      <p class="text-uppercase">
                        УЗБЕКИСТАН, ТАШКЕНТСКИЙ ВИЛ., УЛ. САҒБОН-2А
                      </p>
                    </div>
                  </div>
                  <div class="contact-item media">
                    <i class="fa fa-mobile media-left media-right-margin"></i>
                    <div class="media-body">
                      <p class="text-uppercase">Телефон</p>
                      <p class="text-uppercase">
                        <a class="text-white" href="tel:+998(99)5203040"
                          >+998 (99) 520 30 40</a
                        >
                      </p>
                    </div>
                  </div>
                  <div class="contact-item media">
                    <i class="fa fa-envelope media-left media-right-margin"></i>
                    <div class="media-body">
                      <p class="text-uppercase">E-mail</p>
                      <p class="text-uppercase">
                        <a class="text-white" href="mailto:abcdefg@gmail.com"
                          >biomedindustry@list.ru</a
                        >
                      </p>
                    </div>
                  </div>
                  <div class="contact-item media">
                    <i class="fa fa-clock media-left media-right-margin"></i>
                    <div class="media-body">
                      <p class="text-uppercase">РАБОЧЕЕ ВРЕМЯ</p>
                      <p class="text-uppercase">Пн-Сб с 8:00 до 18:00</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <!-- <v-alert text="..." type="success" v-></v-alert> -->
</template>

<style src='../css/style.css'>
</style>

<script>
import axios from "axios";
export default {
  data() {
    return {
      token: 11,
      name: "",
      email: "",
      phone: "",
      message: "",
      alert_success: false,
      alert_error: false,
    };
  },
  methods: {
    // Get Now Date
    getNow() {
      const today = new Date();
      const date =
        today.getFullYear() +
        "-" +
        (today.getMonth() + 1) +
        "-" +
        today.getDate();
      const time =
        today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
      const dateTime = date + " " + time;

      return dateTime;
    },
    submit() {
      //   if (!this.checkFormValidity()) {
      //     return;
      //   }

      let fullMessage = "[ABSD - MUROJAT " + this.getNow() + "] %0A";
      fullMessage += "------------------%0A";
      fullMessage += "Name: " + this.name + " %0A";
      //   fullMessage += "Email: " + this.email + " %0A";
      fullMessage += "Phone: " + this.phone + " %0A";
      fullMessage += "%0AMessage %0A";
      fullMessage += this.message;
      fullMessage += "%0A------------------%0A%0A";

      this.sendMessage(fullMessage);
      this.clearCart();
      //   this.showAlert();
    },

    sendMessage(fullMessage) {
      const token = "6504644931:AAHB1qPIuMl9xCJNOy0FdraYTJOT86CcQ3g";
        const chatId = 320968728;
    //   const chatId = 6102467524;

      axios
        .post(
          `https://api.telegram.org/bot${token}/sendMessage?chat_id=${chatId}&text=${fullMessage}`
          // `https://api.telegram.org/bot6504644931:AAHB1qPIuMl9xCJNOy0FdraYTJOT86CcQ3g/sendMessage?chat_id=6102467524&text=avtoclave.uz saytiga murojat keb tushti. biomedindustry@list.ru ni tekshiring`
        )
        .then(
          (response) => {
            console.log("Succsessfully!", response);
            alert(
              "✅ Отправлена! Мы свяжемся с вами в ближайшее время по поводу вашей заявки!"
            );
          },
          (error) => {
            console.log(error);
            alert("Проблема отправки сообщения");
          }
        );
    },

    clearCart() {
      this.name = "";
      //   this.email = ''
      this.phone = "";
      this.message = "";
      //   this.startCheck = false
    },
  },
};
</script>