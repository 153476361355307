<template>
  <div>
    <h1>Dashboard</h1>
    <button @click="fetchData">Load Data</button>
    <table v-if="data.length > 0">
      <thead>
        <tr>
          <th>ID</th>
          <th>Name</th>
          <th>Details</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="item in data" :key="item.id">
          <td>{{ item.id }}</td>
          <td>{{ item.name }}</td>
          <td>{{ item.details }}</td>
        </tr>
      </tbody>
    </table>
    <p v-else>No data loaded</p>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';

export default {
  computed: {
    ...mapState(['data'])
  },
  methods: {
    ...mapActions(['fetchData'])
  }
}
</script>
