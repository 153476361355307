<template>
  <section data-scroll-index="1" id="proizvodsvo">
    <v-container class="pt-15">
      <div class="pa-md-10">
        <v-row class="">
          <v-col cols="12" md="6" sm="12">
            <div
              class="d-flex flex-column fill-height justify-center align-left"
            >
              <h1 class="text-lg-h3 text-h4 mb-3">О компании</h1>
              <!-- <h1 class="text-lg-h3 text-h4 mb-4">Пробиотики</h1> -->
              <h4 style="font-weight: lighter" class="text-h6">
                Наша компания занимается производством дисков для определения
                чувствительности к антибиотикам. Мы обеспечиваем высокое
                качество и надежность продукции, следуя строгим стандартам и
                протоколам на каждом этапе производства.
              </h4>
              <!-- <v-btn width="200" class="mt-5"> Читать далее </v-btn> -->
              <!-- <a href="#" class="about-btn" style="width: 200px;">Читать далее</a> -->
            </div>
          </v-col>
          <v-col cols="12" md="6" sm="12">
            <v-img
              :src="require('@/images/baner-2.jpg')"
              height="400px"
              class="head_baner_img"
              aspect-ratio="4/3"
            ></v-img>
          </v-col>
        </v-row>
      </div>
      <!--  -->

      <div class="pa-md-10">
        <v-row class="">
          <v-col cols="12" md="6" sm="12">
            <v-img
              :src="require('@/images/antibiotik-1.png')"
              height="400px"
              class="head_baner_img"
              aspect-ratio="4/3"
            ></v-img>
          </v-col>

          <v-col cols="12" md="6" sm="12">
            <div
              class="d-flex flex-column fill-height justify-center align-left"
            >
              <h1 class="text-lg-h3 text-h4 mb-3">Наши преимущества</h1>
              <!-- <h1 class="text-lg-h3 text-h4 mb-4">Пробиотики</h1> -->
              <h4 style="font-weight: lighter" class="text-h6">
                <ul>
                  <li>
                    <span class="text_preimushestvo"> Высококачественные материалы: </span>Используем только
                    лучшую фильтровальную бумагу и антибиотики, чтобы
                    гарантировать точность результатов.
                  </li>
                  <li>
                    <b>Современные технологии: </b>Применяем передовые методы
                    для пропитки, сушки и стерилизации дисков.
                  </li>
                  <li>
                    <b>Строгий контроль качества: </b>Все наши продукты проходят
                    тщательное тестирование на активность и стерильность.
                  </li>
                  <!-- <li>
                    <b>Гарантия стерильности: </b>Упаковываем диски в герметичные
                    стерильные контейнеры для сохранения их свойств.
                  </li> -->
                </ul>
              </h4>
              <!-- <v-btn width="200" class="mt-5"> Читать далее </v-btn> -->
              <!-- <a href="#" class="about-btn" style="width: 200px;">Читать далее</a> -->
            </div>
          </v-col>
        </v-row>
      </div>
    </v-container>
  </section>
</template>


<style src='../css/style.css'>
</style>
