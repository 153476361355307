<template>
  <!-- <nav class="navbar navbar-expand-lg fixed-top" :class="{ 'nav-scroll': scrolled }"> -->
  <nav class="navbar navbar-expand-lg fixed-top nav-scroll">
    <div class="container">
      <a class="navbar-brand navbar-logo" href="/">
        <!-- <img src="@/images/logo.png" alt="logo" class="logo-1" /> -->
        <!-- <img :src="require(`@/images/logo/${logoSrc}`)" alt="logo" class="logo-1" /> -->
        <img
          :src="require(`@/images/logo/logo_BioMed.png`)"
          alt="logo"
          class="logo-1"
        />
      </a>
      <button
        class="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="fas fa-bars"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav ml-auto">
          <li class="nav-item">
            <!-- <a class="nav-link" href="" data-scroll-nav="0">ГЛАВНАЯ</a> -->
            <a class="nav-link" href="#main" @click="scrollToSection('0')"
              >ГЛАВНАЯ</a
            >
          </li>
          <li class="nav-item">
            <!-- <a class="nav-link" href="#" @click="scrollToSection(1)">О НАС</a> -->
            <a
              class="nav-link"
              href="#proizvodsvo"
              @click="scrollToSection('1')"
              >О НАС</a
            >
          </li>
          <li class="nav-item">
            <a class="nav-link" href="#products" @click="scrollToSection(2)"
              >Антибиотики</a
            >
          </li>

          <li class="nav-item">
            <a class="nav-link" href="#contact" @click="scrollToSection(3)"
              >КОНТАКТЫ</a
            >
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>



<style scoped>
@import url(../css/style.css);
.v-toolbar__title {
  font-size: 1rem !important;
}
</style>


<script>
import "animate.css";

export default {
  data() {
    return {
      scrolled: true,
      logoSrc: "logo_BioMed_no_bg_white_org.png",
    };
  },
  mounted() {
    // window.addEventListener("scroll", this.handleScroll);
    this.enableSmoothScrolling();
  },
  beforeUnmount() {
    // window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleScroll() {
      const bodyScroll = window.scrollY;
      // var navbar = $(".navbar");

      if (bodyScroll > 50) {
        this.logoSrc = "logo_BioMed.png";
        this.scrolled = true;

        // navbar.addClass("nav-scroll");
      } else {
        this.logoSrc = "logo_BioMed_no_bg_white_org.png";
        this.scrolled = false;
      }
    },

    scrollToSection2(index) {
      const sections = document.querySelectorAll("[data-scroll-index]");
      const targetSection = sections[index];
      console.log("index : ", index);

      if (targetSection) {
        console.log("taget : ", targetSection);
        const offsetTop = targetSection.offsetTop;
        console.log("offsetTop : ", offsetTop);
        window.scrollTo({
          top: offsetTop,
          behavior: "smooth",
        });
      }
    },

    scrollToSection(index) {
      const sections = document.querySelectorAll("data-scroll-index");
      const targetSection = sections[index];
      console.log("index : ", index);
      if (targetSection) {
        const offsetTop =
          targetSection.getBoundingClientRect().top + window.pageYOffset;
        console.log("offsetTop : ", offsetTop);
        window.scrollTo({
          top: offsetTop,
          behavior: "smooth",
        });
      }
    },

    enableSmoothScrolling() {
      document.querySelectorAll('a[href^="#"]').forEach((anchor) => {
        anchor.addEventListener("click", function (e) {
          e.preventDefault();

          document.querySelector(this.getAttribute("href")).scrollIntoView({
            behavior: "smooth",
          });
        });
      });
    },
  },
};
</script>